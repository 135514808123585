
.chat-list {
  padding: 10px 0 80px;
  margin: 0 15px;
}
.chat-item {
  display: flex;
  margin-bottom: 20px;
}
.right-chat-item {
  justify-content: flex-end;
}
.avatar {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.right-chat-item .avatar {
  margin-left: 10px;
  margin-right: 0;
}
.content {
  width: fit-content;
  max-width: 68%;
  padding: 10px;
  background: #eee;
  text-align: left;
  white-space: pre-line;
  border-radius: 2px;
}
.right-chat-item .content {
  background: #1677ff;
  color: #fff;
}
.dot-ani {
  display: inline-block;
  height: 12px;
  line-height: 12px;
  overflow: hidden;
}
.dot-ani::after {
  display: inline-table;
  white-space: pre;
  content: "\A.\A..\A...";
  animation: spin 2s steps(4) infinite;
}
@keyframes spin {
  to{
    -webkit-transform:translateY(-48px);
    transform:translateY(-48px)
  }
}