.tip-section {
  margin-top: 100px;
}
.usage-title {
  margin-left: 10px;
}
.question-item {
  width: 56%;
  background: #eee;
  margin: 0 auto 15px;
  padding: 10px;
  cursor: pointer;
}