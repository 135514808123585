.App {
  padding-top: 20px;
  text-align: center;
}

.input-section {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 10%;
  display: flex;
  flex-direction: row;
  background: #eee;
}
.input-btn {
  margin-left: 10px;
}